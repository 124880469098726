import styled from 'styled-components'

export const DoaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85vh;
  flex-direction: column;
  padding: 0 60px;
`

export const DoaContent = styled.div`
  img {
    width: 100%;
  }
`

export const DoaLatin = styled.div`
  margin: 40px 0 30px;
  color: #4A4A4A;
  font-style: italic;
`