import styled, { css } from 'styled-components'
const SpinnerWrapper = styled.div`
  ${props =>
    props.width &&
    css`
      width: ${props => props.width}px;
    `}
  margin: 0 auto;
  text-align: center;

  .spinner {
    animation: rotate 1.4s linear infinite;
    -webkit-animation: rotate 1.4s linear infinite;
    -moz-animation: rotate 1.4s linear infinite;
    width: 24px;
    height: 24px;
    position: relative;
  }

  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes rotate {
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @-moz-keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }

  .path {
    stroke-dasharray: 170;
    stroke-dashoffset: 20;
  }
`
export { SpinnerWrapper }
