import React from 'react'
import Zakat from 'components/Zakat'

const ZakatContainer = (props) => {
  return (
    <Zakat {...props} />
  )
}

export default ZakatContainer
