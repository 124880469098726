import React, { useState } from 'react'

import Profesi from './Profesi'
import Doa from './Doa'
import {
  Container,
  Content,
  NavContainer,
  NavTitle,
  PoweredBy,
} from './style'

const Zakat = (props) => {
  const [isFormFilled, setIsFormFilled] = useState(false)
  const [params, setParams] = useState(null)
  const onSubmit = (data) => {
    setParams(data)
    setIsFormFilled(true)
  }

  document.body.style.backgroundColor = isFormFilled ? '#FFF' : '#F7F7F7'

  return (
    <Container>
      <NavContainer>
        <NavTitle>
          Zakat Profesi
        </NavTitle>
      </NavContainer>
      { !isFormFilled ? (
        <Content>
          <PoweredBy>
            <img src="https://assets.kitabisa.cc/images/poweredby/pwrbykb.svg" alt="powered by kitabisa" />
          </PoweredBy>
          <Profesi history={props.history} setParams={onSubmit} />
        </Content>
      ) : <Doa params={params} /> }

    </Container>
  )
}

export default Zakat
