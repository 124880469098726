import styled from 'styled-components'

export const Container = styled.div<{aboveNisab?: boolean;isOpen: boolean}>`
  position: fixed;
  bottom: ${props => props.isOpen ? '0' : '100%'};
  left: 0;
  right: 0;
  border-radius: 15px 15px 0 0;
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  background: #fff;
  z-index: 13;
  padding: ${props => props.aboveNisab ? '44px 16px 105px' : '44px 16px 30px'}
`
export const Title = styled.h3`
  font-size: 16px;
  line-height: 22px;
  color: #3A3A3A;
  margin: 0 0 16px;
  font-weight: 600;
`
export const Nominal = styled.span`
  font-size: 27px;
  line-height: 37px;
  font-weight: 700;
  color: #3A3A3A;
  display: block;
  margin-bottom: 13px;
`
export const SelectMerchant = styled.button`
  border-radius: 4px;
  border: 1px solid #D8D8D8;
  -webkit-appearance: none;
  background: #fff;
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 16px;
  span {
    font-size: 14px;
    line-height: 19px;
    color: #4A4A4A;
  }
  svg {
    width: 18px;
    height: 18px;
    color: #D8D8D8;
  }
  img {
    display: block;
    margin-right: 5px;
    width: 60px;
  }
`
export const PaymentMethodContainer = styled.div`
  text-align: left;
  border-radius: 4px;
  border: 1px solid #D8D8D8;
  margin-top: 20px;
`

export const CtaContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 16px;
`
export const ClipboardText = styled.button`
  font-size: 12px;
  line-height: 17px;
  color: #00AFEF;
  font-weight: 700;
  position: absolute;
  top: 8px;
  right: 12px;
  -webkit-appearance: none;
  border: none;
  background: none;
`
export const CloseModal = styled.button`
  position: absolute;
  -webkit-appearance: none;
  border: none;
  background: none;
  right: 16px;
  top: 16px;
`
