import React from 'react'
import {
  Container,
  Wrapper,
  Header,
  Title
} from './style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft'

interface Props {
  children: any;
  closeModal: any;
  title: string;
}

const ModalComponent = (props) => {
  return (
    <Container>
      <Header>
        <div
          style={{
            maxWidth: 480,
            margin: '0 auto',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <FontAwesomeIcon onClick={() => props.closeModal()} icon={faArrowLeft} />
          <Title>{props.title}</Title>
        </div>
      </Header>
      <Wrapper>
        {props.children}
      </Wrapper>
    </Container>
  )
}

export default ModalComponent
