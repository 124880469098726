import React, { useEffect, useState } from 'react'
import {
  Container,
  Row,
  Image,
  Name
} from './style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck'
import ContentLoader from 'react-content-loader'

interface Props {
  data?: any;
  selectedID?: number;
  selectedMerchant?: any;
  setSelectedMerchant?: any;
  closeModal?: any;
}
const AmilZakat = (props) => {
  const [selected, setSelected] = useState(null)
  useEffect(() => {
    if (props.selectedID) {
      setSelected(props.selectedID)
    }
  }, [props.selectedID])
  const onChange = (obj) => {
    setSelected(obj.id)
    props.setSelectedMerchant(obj)
    props.closeModal(false)
  }
  if (!props.data) {
    return (
      <Container>
        {[1,2,3,4,5].map((index) => {
          return (
            <ContentLoader
              key={index}
              speed={2}
              width={480}
              height={50}
              viewBox="0 0 480 50"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="94" y="12" rx="3" ry="3" width="91" height="6" />
              <rect x="93" y="27" rx="3" ry="3" width="52" height="6" />
              <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
              <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
              <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
              <rect x="1" y="7" rx="0" ry="0" width="73" height="57" />
            </ContentLoader>
          )
        })}
      </Container>
    )
  }
  return (
    <Container>
      {props.data.data.map(item => {
        return (
          <Row key={item.id}
            onClick={() => onChange(item)}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Image src={item.image} alt={item.title} />
              <Name>{item.campaigner}</Name>
            </div>
            {
              (selected === item.id) ?
                <FontAwesomeIcon icon={faCheck} style={{ color: '#00AEEF' }} />
                : null
            }
          </Row>
        )
      })}
    </Container>
  )
}

export default AmilZakat
