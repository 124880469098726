/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { nominalToThousand, fetchData, eventTracker } from '@3pp/utils'
import { SwitchToggle, Button  } from '@3pp/ui-library'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faChevronDown } from '@fortawesome/pro-light-svg-icons'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import DonorInfo from '../DonorInfo'
import ModalComponent from '../ModalComponent'
import AmilZakat from '../AmilZakat'
import Modal from 'react-modal'
import customHeaders from 'utils/customHeaders'
import {
  Container,
  Title,
  Nominal,
  SelectMerchant,
  CtaContainer,
  CloseModal,
  ClipboardText
} from './style'

interface Props {
  title?: string;
  nominal?: string;
  showFlashMessage?: any;
  history?: any;
  isOpen?: boolean;
  closeModal: any;
  setParams: any;
  aboveNisab: boolean;
}

Modal.setAppElement('#root')

const Account: React.FC<Props> = (props) => {
  const [anonym, setAnonym] = useState(false)
  const [selectedMerchant, setSelectedMerchant] = useState(null)
  const [merchantModal, setMerchantModal] = useState(false)
  const [merchantData, setMerchantData] = useState(null)
  const [nameError, setNameError] = useState('')
  const [emailPhoneError, setEmailPhoneError] = useState('')
  const [name, setName] = useState('')
  const [email_or_phone, setEmailOrPhone] = useState('')
  const [ignoreNisab, setIgnoreNisab] = useState(false)

  const getMerchant = async () => {
    setMerchantModal(true)
    if(merchantData === null){ 
      const merchantApi = await fetchData({
        url: `${process.env.REACT_APP_SEKAWAN}/v1/auth/campaigns-all?partner_id=${process.env.REACT_APP_PARTNER_ID}`,
        additionalHeaders: customHeaders
      })

      setMerchantData(merchantApi.data[0])
    }
  }

  const validateForm = () => (selectedMerchant !== null && name !== '' && email_or_phone !== '' && nameError === '' && emailPhoneError === '') ? true : false
  
  const submitForm = async () => {
    eventTracker('button tunaikan sekarang zakat linkaja syariah')
    if (validateForm()) {
      const params = {
        name,
        email_or_phone: email_or_phone.replace(/\s+$/, ''),
        campaign_id: selectedMerchant['id'],
        amount: Number(props.nominal),
        is_anonymous: anonym,
      }
      return props.setParams(params)
    }
  }
  const onCloseModal = () => {
    props.closeModal()
    setIgnoreNisab(false)
  }
  return (
    <Container isOpen={props.isOpen} aboveNisab={props.aboveNisab || ignoreNisab} >
      <CloseModal onClick={() => onCloseModal()}>
        <FontAwesomeIcon icon={faTimes}/>
      </CloseModal>
      <Modal isOpen={merchantModal} style={{overlay: {zIndex : 13}}}>
        <ModalComponent
          closeModal={() => setMerchantModal(false)}
          title="Pilih Lembaga Amil Zakat"
          history={props.history}
        >
          <AmilZakat
            aboveNisab={props.aboveNisab}
            closeModal={() => setMerchantModal(false)}
            selectedMerchant={selectedMerchant}
            setSelectedMerchant={setSelectedMerchant}
            data={merchantData}
          />
        </ModalComponent>
      </Modal>
      {
        (props.aboveNisab || ignoreNisab) ?
          <>
            <Title>{props.title}</Title>
            <div style={{ position: 'relative' }}>
              <Nominal>Rp. {nominalToThousand(props.nominal)}</Nominal>
              <CopyToClipboard text={props.nominal}>
                <ClipboardText onClick={() => props.showFlashMessage()}>
                  SALIN
                </ClipboardText>
              </CopyToClipboard>
            </div>
            <SelectMerchant onClick={() => getMerchant()}>
              {
                (selectedMerchant) ?
                  <div style={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'center' }}>
                    <img src={selectedMerchant.image} alt={selectedMerchant.campaigner} />
                    <span>{selectedMerchant.campaigner}</span>
                  </div>
                  :
                  <>
                    <span>Pilih Lembaga Amil Zakat</span>
                  </>

              }
              <FontAwesomeIcon icon={faChevronDown}/>
            </SelectMerchant>
            <DonorInfo
              name={name}
              emailOrPhone={email_or_phone}
              emailPhoneError={emailPhoneError}
              setEmailOrPhone={setEmailOrPhone}
              setEmailPhoneError={setEmailPhoneError}
              setName={setName}
              setNameError={setNameError}
              nameError={nameError}
            />
            <SwitchToggle name="anonym" onChange={() => setAnonym(!anonym)} label="Sembunyikan nama saya (donasi anonim)"/>
            <CtaContainer>
              <Button
                disabled={!validateForm()}
                backgroundColor="#47B096"
                height="40px"
                fontSize="14px"
                margin="0"
                style={{
                  borderRadius: 4,
                  maxWidth: 480,
                  margin: '0 auto'
                }}
                onClick={() => {
                  submitForm()
                }}
                label='TUNAIKAN SEKARANG'
              />
            </CtaContainer>
          </>
          :
          <>
            <h3 style={{ fontSize: 16, lineHeight: '22px', fontWeight: 600, margin: 0 }}>Zakat Belum Memenuhi Nisab</h3>
            <img src="https://assets.kitabisa.cc/images/icons/icon__zakat_nisab.svg" alt="nisab" style={{ margin: '25px 0 30px'}} />
            <Button
              backgroundColor="#6BD34A"
              height="40px"
              fontSize="14px"
              margin="0 0 16px!important"
              style={{
                borderRadius: 4,
                maxWidth: 480,
                margin: '0 auto'
              }}
              onClick={() => {
                setIgnoreNisab(true)
              }}
              label='ZAKAT SEKARANG'
            />
            <Button
              backgroundColor="#fff"
              height="40px"
              fontSize="14px"
              margin="0"
              style={{
                color: '#6BD34A',
                borderRadius: 4,
                maxWidth: 480,
                margin: '0 auto'
              }}
              onClick={() => {
                onCloseModal()
              }}
              label="HITUNG ULANG"
            />
          </>
      }
    </Container>
  )
}

export default Account
