import React from 'react'
import validatePhoneMail from 'utils/validatePhoneMail'

import {
  Section,
  TextFieldInput,
  TextFieldLabel,
  TextFieldWrapper,
  TextFieldSpan,
  LabelInputError
} from './styles'

const DonorInfo = ({
  name,
  emailOrPhone,
  emailPhoneError,
  setEmailOrPhone,
  setEmailPhoneError,
  setName,
  setNameError,
  nameError,
}) => {
  return (
    <React.Fragment>
      <Section>
        <TextFieldWrapper>
          <TextFieldInput
            id="name"
            value={name}
            required
            onChange={event => {
              setName(event.target.value)
              if (event.target.value.length < 1) {
                setNameError('Nama lengkap tidak boleh kosong.')
              } else if (event.target.value.length > 70) {
                setNameError('Nama lengkap terlalu panjang (maksimal 70 karakter).')
              } else if (event.target.value && !/^[A-Za-z. ']+$/.test(event.target.value)) {
                setNameError("Nama lengkap hanya boleh huruf, titik (.) dan apostrof (')")
              } else {
                setNameError('')
              }
            }}
          />
          <TextFieldLabel>
            <TextFieldSpan>Nama lengkap</TextFieldSpan>
          </TextFieldLabel>
        </TextFieldWrapper>
        {nameError && <LabelInputError>{nameError}</LabelInputError>}
        <TextFieldWrapper>
          <TextFieldInput
            id="emailOrPwd"
            value={emailOrPhone}
            onChange={event => {
              setEmailOrPhone(event.target.value)
              setEmailPhoneError(validatePhoneMail(event.target.value))
            }}
            required
          />
          <TextFieldLabel>
            <TextFieldSpan>No. WhatsApp atau Email</TextFieldSpan>
          </TextFieldLabel>
        </TextFieldWrapper>
        {emailPhoneError && <LabelInputError>{emailPhoneError}</LabelInputError>}
      </Section>
    </React.Fragment>
  )
}

export default DonorInfo
