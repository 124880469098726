import styled from 'styled-components'

export const Container = styled.div`
  max-width: 480px;
  width: 100%;
  margin: 0 auto 0;
`

export const Content = styled.div`
  padding: 16px;
`
export const NavContainer = styled.div`
  background-color: #47B096;
  display: flex;
  position: relative;
  padding-left: 16px;
`

export const NavTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  padding: 16px 0;
  color: #fff
`

export const PoweredBy = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 5px 0 16px;
`
