import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { parse } from 'query-string'
import Home from 'containers/Home'
import { FailedPage } from '@3pp/ui-library'
import 'styles/main.scss'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact render={(props) => (
          <Home {...props} />
        )} />
        <Route path="/failed" exact render={(props) => (
          <FailedPage { ...parse(props.location.search) } />
        )} />
        <Route render={(props) => (
          <FailedPage 
            title="Halaman tidak ditemukan" 
            information="Saat ini kamu belum bisa mengakses halaman ini, kamu bisa kembali lagi nanti."
            from="/"
          />
        )} />
      </Switch>
    </Router>
  );
};

export default App;
