import React from 'react'
import { SpinnerWrapper } from './style'

export const Spinner = ({ width = 200 }) => (
  <SpinnerWrapper width={width}>
    <svg
      className="spinner"
      width="32px"
      height="32px"
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className="path"
        fill="transparent"
        strokeWidth="3"
        cx="33"
        cy="33"
        r="30"
        stroke="url(#gradient)"
      />
      <linearGradient id="gradient">
        <stop offset="50%" stopColor="#fff" stopOpacity="1" />
        <stop offset="65%" stopColor="#fff" stopOpacity=".5" />
        <stop offset="100%" stopColor="#fff" stopOpacity="0" />
      </linearGradient>
    </svg>
  </SpinnerWrapper>
)

export default Spinner
