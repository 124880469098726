import React, { useState } from 'react'
import { Button  } from '@3pp/ui-library'
import Spinner from 'components/common/Spinner'
import postDonation from 'utils/postDonation'
import { eventTracker } from '@3pp/utils'

import {
  DoaContainer,
  DoaContent,
  DoaLatin,
} from './style'

const Zakat = (props) => {
  const [formLoading, setFormLoading] = useState(false)

  return (
    <DoaContainer>
      <DoaContent>
        <img src="https://assets.kitabisa.cc/images/zakat/summary_zakat-prefesi.png" alt="summary zakat" />
      </DoaContent>
      <DoaLatin>
        "Semoga Allah memberikan pahala kepadamu pada barang yang engkau berikan (zakatkan) dan semoga Allah memberkahimu dalam harta-harta yang masih engkau sisakan dan semoga pula menjadikannya sebagai pembersih (dosa) bagimu"
      </DoaLatin>
      <Button
        backgroundColor="#47B096"
        height="40px"
        fontSize="14px"
        margin="0"
        style={{
          borderRadius: 4,
          maxWidth: 480,
          margin: '0 auto'
        }}
        onClick={() => {
          eventTracker('button amin zakat linkaja syariah')
          postDonation(props.params, setFormLoading)
        }}
        label={formLoading ? <Spinner width={30} /> : 'AAMIIN'}
      />
    </DoaContainer>
  )
}

export default Zakat
