import customHeaders from 'utils/customHeaders'
import appendUtmSource from 'utils/appendUtmSource'
import { fetchData } from '@3pp/utils'

async function postDonation(params, loadingFn, redirectFn = window.location.href) {
  const body = {
    ...params,
    campaigner_relation: false,
    comment: "",
    payment_methods_id: Number(process.env.REACT_APP_PAYMENT_METHOD_ID),
    platform: 'pwa',
    redirect_callback_params: "?donate_to: non-proteksi",
    redirect_callback: `${window.location.protocol}//${window.location.host}/donation/status/`,
    send_notification: true,
    utm: {
      source: process.env.REACT_APP_UTM_SOURCE,
      medium: null,
      campaign: null
    }
  }
  const submittedData = await fetchData({
    method: 'POST',
    url: `${process.env.REACT_APP_SEKAWAN}/v1/auth/non-login-donation`,
    body,
    additionalHeaders: customHeaders
  })
  loadingFn(false)
  window.location.href = appendUtmSource(submittedData.data[0].payment_checkout_url)
}

export default postDonation
