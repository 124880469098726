import styled from 'styled-components'

export const Container = styled.div`
  padding: 0 16px 20px;
  z-index: 100;
`
export const Image = styled.img`
  display: block;
  width: 60px;
  height: auto;
  margin-right: 20px;
`
export const Name = styled.span`
  display: block;
  font-size: 14px;
  line-height: 19px;
  color: #4A4A4A;
`
export const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
  justify-content: space-between;
`
