export default function validatePhoneMail(input) {
  if(input.length < 1) {
    return 'Nomor WhatsApp atau Email tidak boleh kosong.'
  }
  if(input.length > 50) {
    return 'Karakter Nomor WhatsApp atau email terlalu panjang (maksimal 50 karakter)'
  }
  if(!/^([0-9]{9})|([A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3})$/.test(input)) {
    return 'Hanya diisi dengan nomor WhatsApp atau email.'
  }
  if(!input.includes('@')) {
    if(parseInt(input) < 1) {
      return 'Hanya diisi dengan nomor WhatsApp atau email.'
    }
  }
  return ''
 }