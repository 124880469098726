import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
`
export const Wrapper = styled.div`
  overflow-y: scroll;
  height: 100%;
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
`
export const Header = styled.div`
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
  padding: 19px 16px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const Title = styled.h2`
  margin: 0 0 0 10px;
  font-size: 16px;
  line-height: 22px;
  color: #3A3A3A;
  font-weight: 600;
`
