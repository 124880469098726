import styled from 'styled-components'

export const Section = styled.div`
  margin: 10px 0;
`
export const TextFieldWrapper = styled.div`
  position: relative;
  height: 50px;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: ${props => (props.isError ? '0' : '10px')};
  border-radius: 3px;
`

export const TextFieldLabel = styled.label`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  color: #989898;
  border-bottom: 1px solid
    ${props => (props.isError ? '#f80505' : '#d8d8d8')};

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    height: 100%;
    width: 100%;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }
`

export const TextFieldSpan = styled.span`
  position: absolute;
  bottom: 5px;
  left: 0;
  transition: all 0.3s ease;
  font-size: 14px;
`

export const TextFieldInput = styled.input`
  width: 100%;
  font-size: 16px;
  height: ${props => props.height || '45px'};
  color: #595f6e;
  padding-top: 20px;
  border: none;
  outline: none;
  background: #fff;
  transition: all 0.3s;
  border-radius: 3px;
  flex-grow: 1;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.4;
    background: rgba(0,0,0,0.3);
  }

  &[readOnly] {
    opacity: 0.4;
    background: rgba(0,0,0,0.3);
  }

  &[type='password'] {
    font-size: 1em;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  & + input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:focus
    + ${TextFieldLabel}
    ${TextFieldSpan},
    &:valid
    + ${TextFieldLabel}
    ${TextFieldSpan} {
    transform: translateY(-100%);
    padding-bottom: 2px;
  }

  &:focus + ${TextFieldLabel}::after, &:valid + ${TextFieldLabel}::after {
    transform: translateX(0%);

    ${TextFieldLabel} {
      border-bottom: none;
    }
  }
`
export const LabelInputError = styled.span`
  font-size: 12px;
  color: #f80505;
  margin: 5px 0 10px 0;
  display: flex;
`
