import styled from 'styled-components'

export const Row = styled.div`
  margin-bottom: 16px;
`
export const Label = styled.span`
  display: block;
  font-size: 14px;
  line-height: 19px;
  color: #4D4D4D;
  margin-bottom: 11px;
`
export const Asterisks = styled.span`
  color: #C7265D;
`
export const Overlay = styled.div<{isOpen?: boolean;onClick: any}>`
  background: rgba(0,0,0,0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: ${props => props.isOpen ? '0' : '100%'};
  right: 0;
  bottom: 0;
  z-index: 1;
`

export const CtaContainer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 16px;
  box-shadow: 0 -0.5px 1.5px 0 rgba(157, 157, 157, 0.5);
  background-color: #F7F7F7;
`

